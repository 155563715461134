<template>
	<div class="playlistCreate">

		<h1 class="text-center">Wann soll’s losgehen?</h1>

		<div class="fitnessplanDateSelection">
			<div class="fitnessplanDateSelection__calendar">
				<vc-calendar is-expanded :min-date="new Date()" locale="de" :step="1" @dayclick="onDayClick" v-model="startDate"/>
			</div>
		</div>

		<v-btn :disabled="startDate.valueOf() < new Date().toISOString().slice(0,10).valueOf()" class="button button--primary"  @click="checkChallengeDetails">Weiter</v-btn>
	</div>
</template>

<script>

import {toIsoString} from "@/sportlerplus-helper";

export default {
	name: 'FitnessplanChallengeSelectDate',
	data() {
		return {
			startDate: "",

		}
	},
	methods: {
		checkChallengeDetails() {
			this.$router.push("/fitnessplan/challenge/selectTime?startDate=" + this.startDate +
				"&id=" + this.$route.query.id);
		},
		onDayClick(day) {
			this.startDate = toIsoString(day.date).split("T")[0];
		},
	}
}

</script>

<style lang="scss" scoped>
	/* TODO: REMOVE CODE IF SCSS FROM PLAYLISTCREATENAME EFFECTS GLOBALLY */
	.playlistCreate {
		display: flex;
		flex-direction: column;
		min-height: 100%;

		h1 {
			margin-top: 31px;
			margin-bottom: 7px;
		}

		p {
			margin-bottom: 25px;
		}

		.list__item__label {
			font-size: 17px;
		}

		.button {
			margin-top: auto;
		}
	}

	.fitnessplanDateSelection {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 23px;

		&__item {
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			width: calc((100% - 36px) / 4);
			background: $colorWhite;
			box-shadow: 0 8px 16px 0 rgba($colorBlack,0.04);
			border-radius: 8px;
			padding: 11px 0 14px;
			margin-bottom: 12px;
			overflow: hidden;

			&--selected {
				position: relative;
				box-shadow: 0 2px 16px 0 rgba($colorPrimary,0.12), inset 0 0 0 2px $colorPrimary;

				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: rgba($colorPrimary, .24);
				}
			}

			&__title {
				position: relative;
				z-index: 1;
				font-size: 22px;
				font-weight: 700;
				letter-spacing: -0.32px;
				color: $colorBlack;
				text-transform: uppercase;

				.fitnessplanDateSelection__item--selected & {
					color: $colorPrimary;
				}
			}

			&__date {
				position: relative;
				z-index: 1;
				font-size: 14px;
				font-weight: 700;
				letter-spacing: -0.21px;
				color: rgba($colorBlack, .48);
				margin-top: -6px;

				.fitnessplanDateSelection__item--selected & {
					color: $colorPrimary;
				}
			}
		}

		&__divider {
			position: relative;
			display: flex;
			width: 100%;
			justify-content: center;
			font-size: 14px;
			font-weight: 600;
			letter-spacing: 0.21px;
			color: rgba($colorBlack, .24);
			text-transform: uppercase;
			margin: 17px 0;

			&:after, &:before {
				content: '';
				position: absolute;
				width: calc(50% - 32px);
				height: 1px;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				background: rgba($colorBlack, .08);
			}

			&:after {
				right: 0;
				left: inherit;
			}
		}
	}
</style>

<style lang="scss">
	/* OVERWRITES FOR CALENDAR */
	.fitnessplanDateSelection{

		&__calendar {
			width: 100%;
			background: $colorWhite;
			box-shadow: 0 8px 16px 0 rgba($colorBlack, .04), 0 0 0 1px rgba($colorBlack, .08);
			border-radius: 4px;
		}

		.vc-container {
			border: 0;
			font-family: $fontPrimary;
		}

		.vc-header {
			padding: 20px 16px;

			.vc-title {
				font-size: 14px;
				font-weight: 600;
				letter-spacing: -0.21px;
				line-height: 20px;
				color: $colorBlack;
			}
		}

		.vc-weekday {
			font-size: 11px;
			letter-spacing: -0.16px;
			color: rgba($colorBlack, .24);
		}

		.vc-day, .vc-day-content {
			font-size: 14px;
			font-weight: 600 !important;
			color: $colorBlack;

			&.is-disabled {
				color: rgba($colorBlack, .24);
			}
		}

		.vc-day-content {
			width: 32px;
			height: 32px;
			border-radius: 4px;

			&:focus {
				background: $colorPrimary;
				color: $colorWhite;
			}
		}
	}
</style>